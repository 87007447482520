import NiceModal from "@ebay/nice-modal-react";
import { Box, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SEO from "../../components/old/seo";
import { BodyPadding } from "../../components/shared/body_utils";
import Footer from "../../components/shared/Footer/Footer";
import { L, language } from "../../traductions";
import CreationSection from "./components/CreationSection/CreationSection";
import OutlinedButton from "../../components/shared/OutlinedButton";
import TextSection from "./components/TextSection/TextSection";

import { notFoundPageTheme } from "./theme/notFoundPageTheme";
import { ILocation } from "../../layouts";

const queryClient = new QueryClient();

const NotFoundPage = (props: { location: ILocation }) => {
  try {
    if ((props.location || location).pathname.includes("/it/")) {
      language.code = "it";
    }
  } catch (e) {}

  return (
    <>
      <SEO title={L("pageNotFound")} />
      <ThemeProvider theme={notFoundPageTheme}>
        <QueryClientProvider client={queryClient}>
          <NiceModal.Provider>
            <BodyPadding
              noAutoMargin
              containerCss={{
                margin: "0px auto",
                // minHeight: "calc(100vh - 111px)",
                // boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    lg: "repeat(2, 1fr)",
                  },
                  alignItems: "center",
                  gap: 8,
                  paddingBottom: { xs: 8, lg: 0 },
                }}
              >
                <TextSection />
                <CreationSection />
                <OutlinedButton
                  onClick={() => {
                    NiceModal.show("OtherCreationsDialog");
                  }}
                  children={L("notFound.watchCreations")}
                  sx={{
                    width: "100%",
                    maxWidth: "367px",
                    justifySelf: "center",
                    display: { xs: "flex", lg: "none" },
                  }}
                />
              </Box>
            </BodyPadding>
          </NiceModal.Provider>
        </QueryClientProvider>
      </ThemeProvider>
      <Footer />
    </>
  );
};
export default NotFoundPage;
